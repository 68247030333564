/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

import { createFileRoute } from '@tanstack/react-router'

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as AuthImport } from './routes/_auth'

// Create Virtual Routes

const AuthIndexLazyImport = createFileRoute('/_auth/')()
const AuthAccountsLazyImport = createFileRoute('/_auth/accounts')()

// Create/Update Routes

const AuthRoute = AuthImport.update({
  id: '/_auth',
  getParentRoute: () => rootRoute,
} as any)

const AuthIndexLazyRoute = AuthIndexLazyImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => AuthRoute,
} as any).lazy(() => import('./routes/_auth/index.lazy').then((d) => d.Route))

const AuthAccountsLazyRoute = AuthAccountsLazyImport.update({
  id: '/accounts',
  path: '/accounts',
  getParentRoute: () => AuthRoute,
} as any).lazy(() =>
  import('./routes/_auth/accounts.lazy').then((d) => d.Route),
)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/_auth': {
      id: '/_auth'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof AuthImport
      parentRoute: typeof rootRoute
    }
    '/_auth/accounts': {
      id: '/_auth/accounts'
      path: '/accounts'
      fullPath: '/accounts'
      preLoaderRoute: typeof AuthAccountsLazyImport
      parentRoute: typeof AuthImport
    }
    '/_auth/': {
      id: '/_auth/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof AuthIndexLazyImport
      parentRoute: typeof AuthImport
    }
  }
}

// Create and export the route tree

interface AuthRouteChildren {
  AuthAccountsLazyRoute: typeof AuthAccountsLazyRoute
  AuthIndexLazyRoute: typeof AuthIndexLazyRoute
}

const AuthRouteChildren: AuthRouteChildren = {
  AuthAccountsLazyRoute: AuthAccountsLazyRoute,
  AuthIndexLazyRoute: AuthIndexLazyRoute,
}

const AuthRouteWithChildren = AuthRoute._addFileChildren(AuthRouteChildren)

export interface FileRoutesByFullPath {
  '': typeof AuthRouteWithChildren
  '/accounts': typeof AuthAccountsLazyRoute
  '/': typeof AuthIndexLazyRoute
}

export interface FileRoutesByTo {
  '/accounts': typeof AuthAccountsLazyRoute
  '/': typeof AuthIndexLazyRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/_auth': typeof AuthRouteWithChildren
  '/_auth/accounts': typeof AuthAccountsLazyRoute
  '/_auth/': typeof AuthIndexLazyRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths: '' | '/accounts' | '/'
  fileRoutesByTo: FileRoutesByTo
  to: '/accounts' | '/'
  id: '__root__' | '/_auth' | '/_auth/accounts' | '/_auth/'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  AuthRoute: typeof AuthRouteWithChildren
}

const rootRouteChildren: RootRouteChildren = {
  AuthRoute: AuthRouteWithChildren,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/_auth"
      ]
    },
    "/_auth": {
      "filePath": "_auth.tsx",
      "children": [
        "/_auth/accounts",
        "/_auth/"
      ]
    },
    "/_auth/accounts": {
      "filePath": "_auth/accounts.lazy.tsx",
      "parent": "/_auth"
    },
    "/_auth/": {
      "filePath": "_auth/index.lazy.tsx",
      "parent": "/_auth"
    }
  }
}
ROUTE_MANIFEST_END */
