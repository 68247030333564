import { useState, SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import {
  propEq, find, indexBy,
} from 'rambdax';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ReactCountryFlag from 'react-country-flag';
import LanguageIcon from '@mui/icons-material/Language';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

import LANGUAGES from '../../utils/language';

const LANGUAGES_BY_CODE = indexBy('value', LANGUAGES);
const LANGUAGES_IN_USE = LANGUAGES.filter((lang) => !!lang.countryCode);

interface LanguageOption {
  text: string
  value: string
  countryCode?: string
};
interface LanguageSelectProps {
  options?: LanguageOption[]
  asButton?: boolean
};

const LanguageSelect = ({
  options = LANGUAGES_IN_USE,
  asButton = false,
}: LanguageSelectProps) => {
  const { i18n } = useTranslation('components', { keyPrefix: 'language-select' });
  const [ anchorElement, setAnchorElement ] = useState<null | Element>(null);
  const defaultValue = find(propEq(i18n.language, 'value'), options) || options[0];
  // const isRtl = RTL_LANGUAGE_CODES.includes(i18n.language);

  const handleSelectLanguage = (option: LanguageOption) => {
    i18n.changeLanguage(option.value);
    // if page performance becomes an issue, we can reload the page instead
    // setTimeout(() => window.location.reload(), 1);
    handleCloseLanguageMenu();
  };
  const handleOpenLanguageMenu = (event: SyntheticEvent) => {
    setAnchorElement(event.currentTarget);
  };
  const handleCloseLanguageMenu = () => {
    setAnchorElement(null);
  };

  return (
    <>
      {(asButton)
        ? (
          <Button
            startIcon={
              <LanguageIcon />
              }
            onClick={handleOpenLanguageMenu}
          >
            {LANGUAGES_BY_CODE[i18n.language].text}
            <ReactCountryFlag
              countryCode={defaultValue.countryCode || 'us'}
              style={{
                marginTop: 1,
                marginLeft: 8,
              }}
            />
          </Button>
          )
        : (
          <div
            style={{ display: 'flex' }}
            onClick={handleOpenLanguageMenu}
          >
            <LanguageIcon />
            <span
              style={{
                marginLeft: 8,
                marginRight: 8,
              }}
            >
              {LANGUAGES_BY_CODE[i18n.language].text}
            </span>
            <ReactCountryFlag
              countryCode={defaultValue.countryCode || 'us'}
              style={{ marginTop: 5 }}
            />
            <ArrowRightIcon />
          </div>
          )}

      <Menu
        keepMounted
        anchorEl={anchorElement}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        id='menu-appbar'
        open={Boolean(anchorElement)}
        slotProps={{
          paper: {
            style: {
              maxHeight: 300,
            },
          },
        }}
        sx={{ mt: '40px' }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={handleCloseLanguageMenu}
      >
        {options.map((option) => (
          <MenuItem
            key={option.value}
            selected={defaultValue.value === option.value}
            onClick={() => handleSelectLanguage(option)}
          >
            <Typography sx={{
              textAlign: 'center',
              marginRight: 1,
            }}
            >
              {option.text}
            </Typography>
            <ReactCountryFlag countryCode={option.countryCode || 'us'} />
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default LanguageSelect;
