import * as React from 'react';
import { createRootRouteWithContext, Outlet } from '@tanstack/react-router';
import type { Auth0ContextInterface } from '@auth0/auth0-react';

interface AuthenticatedRouterContext {
  auth: Auth0ContextInterface
}

const TanStackRouterDevtools =
  process.env.NODE_ENV === 'production'
    ? () => null // Render nothing in production
    : React.lazy(() =>
    // Lazy load in development
      import('@tanstack/router-devtools').then((res) => ({
        default: res.TanStackRouterDevtools,
      })),
    );

export const Route = createRootRouteWithContext<AuthenticatedRouterContext>()({
  component: () => (
    <>
      <Outlet />
      <React.Suspense>
        <TanStackRouterDevtools />
      </React.Suspense>
    </>
  ),
});
