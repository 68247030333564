export const RTL_LANGUAGE_CODES = [
  'ar',
  'am',
  'az',
  'fa',
  'he',
  'ur',
];

const LANGUAGES = [
  {
    value: 'en-US',
    text: 'English',
    countryCode: 'us',
  },
  {
    value: 'es-MX',
    text: 'Español',
    countryCode: 'mx',
  },
  {
    value: 'es-ES',
    text: 'Español',
    countryCode: 'es',
  },
  {
    value: 'fr',
    text: 'Français',
    countryCode: 'fr',
  },
  {
    value: 'ar',
    text: 'عربى',
  },
  {
    value: 'ko',
    text: '한국어',
  },
  {
    value: 'hi',
    text: 'हिंदी',
  },
  {
    value: 'zh',
    text: '简化字',
  },
  {
    value: 'zh-TW',
    text: '正體字/繁體字',
  },
  {
    value: 'id',
    text: 'bahasa Indonesia',
  },
  {
    value: 'de',
    text: 'Deutsche',
  },
  {
    value: 'ru',
    text: 'русский',
  },
  {
    value: 'pt',
    text: 'Português',
  },
  {
    value: 'ja',
    text: '日本語',
  },
  {
    value: 'ms',
    text: 'Melayu',
  },
  {
    value: 'fa',
    text: 'فارسی',
  },
  {
    value: 'tl',
    text: 'Tagalog',
  },
  {
    value: 'th',
    text: 'ไทย',
  },
  {
    value: 'vi',
    text: 'Tiếng Việt',
  },
  {
    value: 'bn',
    text: 'বাংলা',
  },
  {
    value: 'am',
    text: 'አማርኛ',
  },
  {
    value: 'af',
    text: 'Afrikaans',
  },
  {
    value: 'sq',
    text: 'Shqiptar',
  },
  {
    value: 'az',
    text: 'Azərbaycan',
  },
  {
    value: 'bs',
    text: 'bosanski',
  },
  {
    value: 'bg',
    text: 'Български',
  },
  {
    value: 'hr',
    text: 'Hrvatski',
  },
  {
    value: 'cs',
    text: 'český',
  },
  {
    value: 'da',
    text: 'Dansk',
  },
  {
    value: 'fa-AF',
    text: 'دری',
  },
  {
    value: 'nl',
    text: 'Nederlands',
  },
  {
    value: 'et',
    text: 'eesti keeles',
  },
  {
    value: 'fi',
    text: 'Suomalainen',
  },
  {
    value: 'ka',
    text: 'ქართული',
  },
  {
    value: 'el',
    text: 'Έλληνας',
  },
  {
    value: 'ha',
    text: 'Hausa',
  },
  {
    value: 'he',
    text: 'עברית',
  },
  {
    value: 'hu',
    text: 'Magyar',
  },
  {
    value: 'it',
    text: 'Italiano',
  },
  {
    value: 'lv',
    text: 'Latviešu valoda',
  },
  {
    value: 'no',
    text: 'Norsk',
  },
  {
    value: 'ps',
    text: 'پښتو',
  },
  {
    value: 'pl',
    text: 'Polski',
  },
  {
    value: 'ro',
    text: 'Romanian',
  },
  {
    value: 'sr',
    text: 'Srpski',
  },
  {
    value: 'sk',
    text: 'Slovensky',
  },
  {
    value: 'sl',
    text: 'slovensko',
  },
  {
    value: 'so',
    text: 'Soomaali',
  },
  {
    value: 'sw',
    text: 'Kiswahili',
  },
  {
    value: 'sv',
    text: 'Svenska',
  },
  {
    value: 'ta',
    text: 'தமிழ்',
  },
  {
    value: 'tr',
    text: 'Türk',
  },
  {
    value: 'uk',
    text: 'Українська',
  },
  {
    value: 'ur',
    text: 'اردو',
  },
  {
    value: 'pa',
    text: 'ਪੰਜਾਬੀ',
  },
];

export default LANGUAGES;
